import React from "react"
import { graphql } from "gatsby"

// imported components
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Longcard} from "../components/card";

// imported styles
import mainStyles from "../styles/css/main.module.scss"

export default ({ data }) => {
  return (
    <Layout>
      <SEO 
        title="Job Guide"
        description="Learn how to become a product manager without experience"
      />

      <section className={mainStyles.majorSection}>
        <div className={mainStyles.mainPadding}>

        <div className={[mainStyles.mainExplanatoryContent_wide, mainStyles.paddingBottom3].join(' ')}>
          <h1>Job Guide</h1>
          <p>Our highly rated no-nonsense guide to becoming a product manager</p>
          <div>
            <a href="https://www.producthunt.com/posts/pm-starter-pack?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-pm-starter-pack" target="_blank" rel="noopener noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=153183&theme=light&period=daily" alt="PM Starter Pack - Practical guide on how to get started in product management | Product Hunt Embed" style={{width: 250+'px', height: 54+'px',}} /></a>
          </div>
        </div>

          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Longcard
              cardName = {node.frontmatter.number}
              cardTitle = {node.frontmatter.title}
              cardBody = {node.frontmatter.description}
              cardPage = {node.fields.slug}
            />
          ))}

        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "guide"}}},
      sort: {fields: [frontmatter___number], order: ASC }
    ) 
      {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            number
            description
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`